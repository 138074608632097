<template> 
  <card
    v-model="item"
    @openAuth="$emit('openAuth')"
    @delete="$emit('delete')"
    @refresh="$emit('refresh')"
    @getExternalLink="$emit('getExternalLink')"
  >
    <template #header>
      <b-row align-v="center" class="d-flex flex-nowrap justify-content-between w-100">
        <google-icon class="google-logo ml-1"/>
        <feather-icon
          :icon="active ? 'CheckIcon' : 'XIcon'"
          :class="active ? 'text-primary' : 'text-danger'" 
          size="30"
        />
      </b-row>
    </template>
    <template #footer>
      <b-row class="d-flex flex-nowrap justify-content-end">
        <b-button class="google-button mr-75 p-0" variant="none" @click="$emit('openAuth')">
          <span class="icon-container d-inline-block p-25 mr-25">
            <google-icon class="logo-button"/>
          </span>
          <span class="d-inline-block p-25 mr-25">
            Sign in with Google
          </span>
        </b-button>
      </b-row>
    </template>
  </card>
</template>

<script>
import { BImg, BIcon, BRow, BCol, BButton } from 'bootstrap-vue'
import Card from '@/layouts/components/ThirdPartyApps/Card.vue'
import GoogleIcon from '@/assets/svgIcons/Google.vue'

  export default {
    components: {
      BImg,
      BIcon,
      BRow,
      BCol,
      Card,
      BButton,
      GoogleIcon
    },
    props: {
      value: {
        type: Object,
        default: undefined,
      }
    },
    computed: {
      item: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      active() {
        return this.item.token
      }
    }
  }
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
.google-logo {
  display: inline;
  height: 3rem;
}

.google-button {
  border-radius: 3px;
  padding: 8px 15px 8px 12px;
  background-color: #1a73e8;
  border: 2px solid #1a73e8;
  .logo-button {
    display: inline;
    height: 18px;
    margin: 5px 5px 5px 5px;
  }
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0px 2px 5px 0px #00000094;
  
  .icon-container {
    background-color: white;
  }
}
  
</style>